export enum TimeLapses {
  today = 'today',
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export enum TypeResponse {
  success = 'success',
  error = 'error',
  info = 'info'
}

export enum CallProviders {
  'agora' = 'agora',
  'twillio' = 'twillio'
};

export declare class PaginateResult<T = any> {
  data: {
    docs: T[];
    totalDocs: number;
    limit: number;
    page?: number;
    totalPages: number;
    nextPage?: number | null;
    prevPage?: number | null;
    pagingCounter: number;
    hasPrevPage: boolean;
    hasNextPage: boolean;
  }
}

export interface StatusResponse {
  status: number,
  type: TypeResponse,
  message: string
}

export interface SortersAndPagination {
  page: number,
  limit: number,
  sortBy: string,
  sortOrder: number
}

export let MONTHS = [
  { label: 'months.january', value: 1 },
  { label: 'months.february', value: 2 },
  { label: 'months.march', value: 3 },
  { label: 'months.april', value: 4 },
  { label: 'months.may', value: 5 },
  { label: 'months.june', value: 6 },
  { label: 'months.july', value: 7 },
  { label: 'months.august', value: 8 },
  { label: 'months.september', value: 9 },
  { label: 'months.october', value: 10 },
  { label: 'months.november', value: 11 },
  { label: 'months.december', value: 12 }
];


export interface ResponsePaginated {
  totalDocs: number;
  limit: number;
  page: number;
  totalPages: number;
  nextPage: number;
  prevPage: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  docs?: any[];
}

export interface CommonFilters {
  user: string,
  name: string,
  building: string,
  dni: string,
  docId: string,
  roles: string,
  unitId: string,
  unitNumber: string,
  email: string,
  search: string,
  activeUsers: string,
  pqrsCategory: string,
  pqrsStatus: string,
  reservationStatus: string,
  accessStatus: string,
  accessType: string,
  area: string,
  startDate: string,
  endDate: string,
  groupId: string,
  statusAccess: string,
  typeAccess: string,
  daysAccess: string,
  typeVisit: string,
  callType: string,
  plate: string;
  company: string;
}

export enum DaysEnum {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday'
}

export const DAYS_GLOBALS: string[] = [
  DaysEnum.Sunday,
  DaysEnum.Monday,
  DaysEnum.Tuesday,
  DaysEnum.Wednesday,
  DaysEnum.Thursday,
  DaysEnum.Friday,
  DaysEnum.Saturday
];

export interface GenericModal {
  title?: string;
}