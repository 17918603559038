<div>
  <form [formGroup]="form" class="d-flex w-100">
    <!-- FILTER BY NAME -->
    <input *ngIf="showFilter('name')" class="form-control item-filter filter-input" formControlName="name" placeholder="{{ 'modal.name' | translate }}" />
    
      <!-- FILTER BY COMUNITY -->
    <ng-select (change)="changeCommunityEvent($event)" *ngIf="showFilter('company')" class="pointer ml-2"
      style="width: 15% !important; border: 1px solid #cacaca !important; border-radius: 10px !important"
      placeholder="{{ 'modal.searchCompany' | translate }}" formControlName="company" #buildingCommunity>
      <ng-option *ngFor="let community of communities" [value]="community.buildingId">{{ community.buildingName
        }}</ng-option>
    </ng-select>
    
    <input *ngIf="showFilter('plate')" class="form-control item-filter filter-input" formControlName="plate" placeholder="{{ 'modal.searchPlate' | translate }}" />

    <!-- FILTER BY USER -->
    <ng-select
      (input)="waitingStopTyping($event.target.value)"
      (clear)="clearSearch()"
      *ngIf="showFilter('user')"
      bindLabel="name"
      class="pointer mx-2 input-select"
      placeholder="{{ 'table.user' | translate }}"
      formControlName="user"
      #userInput
    >
      <ng-option *ngFor="let user of users" [value]="user.id">{{ user.name }}</ng-option>
    </ng-select>

    <!-- FILTER BY AREA -->
    <ng-select
      class="pointer"
      style="width: 15% !important; border: 1px solid #cacaca !important; border-radius: 10px !important"
      *ngIf="showFilter('area')"
      placeholder="{{ 'modal.area' | translate }}"
      formControlName="area"
    >
      <ng-option *ngFor="let area of areas" [value]="area.id">{{ area.name | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY COMUNITY -->
    <ng-select
      (change)="changeCommunityEvent($event)"
      *ngIf="showFilter('community')"
      class="pointer ml-2"
      style="width: 15% !important; border: 1px solid #cacaca !important; border-radius: 10px !important"
      placeholder="{{ 'modal.community' | translate }}"
      formControlName="building"
      #buildingCommunity
    >
      <ng-option *ngFor="let community of communities" [value]="community.buildingId">{{ community.buildingName }}</ng-option>
    </ng-select>


    <!-- FILTER BY DNI -->
    <input *ngIf="showFilter('dni')" class="form-control item-filter filter-input" formControlName="dni" placeholder="# {{ 'modal.dni' | translate }}" />

    <!-- FILTER BY ROLES -->
    <ng-select class="pointer input-select" *ngIf="showFilter('role')" placeholder="{{ 'superadministration.table.rol' | translate }}" formControlName="roles">
      <ng-option *ngFor="let role of roles" [value]="role.value">{{ role.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY TYPE VISITS -->
    <ng-select
      *ngIf="showFilter('typeVisit')"
      class="pointer mx-2 input-select"
      placeholder="Tipo de visita"
      formControlName="typeVisit"
    >
      <ng-option *ngFor="let visit of typeVisits" [value]="visit.value">{{ visit.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY UNITS -->
    <ng-select
      (input)="unitFilter(unitInput)"
      *ngIf="showFilter('unit')"
      class="pointer input-select item-filter"
      [virtualScroll]="true"
      placeholder="{{ 'superadministration.table.unit' | translate }}"
      formControlName="unitId"
      #unitInput
    >
      <ng-option *ngFor="let unit of units" [value]="unit.id">{{ unit.number }}</ng-option>
    </ng-select>

    <input *ngIf="showFilter('unitNumber')" class="form-control item-filter filter-input" formControlName="unitNumber" placeholder="{{ 'superadministration.table.unit' | translate }}" />

    <!-- FILTER BY EMAIL -->
    <input
      *ngIf="showFilter('email')"
      class="form-control filter-input"
      formControlName="email"
      placeholder="{{ 'superadministration.table.email' | translate }}"
    />

    <!-- FILTER BY SEARCH PATTERN -->
    <input *ngIf="showFilter('search')" class="form-control item-filter filter-input" formControlName="search" placeholder="{{ 'modal.search' | translate }}" />

    <!-- FILTER BY ACTIVEUSER -->
    <ng-select
      *ngIf="showFilter('activeUsers')"
      class="pointer input-select"
      placeholder="{{ 'building.settings.userActive' | translate }}"
      formControlName="activeUsers"
    >
      <ng-option *ngFor="let option of activeUsersOptions" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY CATEGORY PQRS -->
    <ng-select
      *ngIf="showFilter('pqrsCategory')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'settings.category' | translate }}"
      formControlName="pqrsCategory"
    >
      <ng-option *ngFor="let option of pqrsCategories" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY STATUS PQRS -->
    <ng-select
      *ngIf="showFilter('pqrsStatus')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'building.settings.status' | translate }}"
      formControlName="pqrsStatus"
    >
      <ng-option *ngFor="let option of pqrsStatus" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY CALL TYPE -->
    <ng-select
      *ngIf="showFilter('callType')"
      class="pointer mx-2 input-select item-filter"
      placeholder="{{ 'vigilantCalls.callType' | translate }}"
      formControlName="callType"
    >
      <ng-option *ngFor="let option of typeCalls" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY DATE -->
    <div *ngIf="showFilter('date')" class="ml-2 item-filter">
      <mat-form-field appearance="fill" class="input-1 p-0" style="background-color: white">
        <mat-date-range-input [rangePicker]="picker" style="background-color: white">
          <input matStartDate formControlName="startDate" placeholder="{{ 'invitations.placeholder.date-from' | translate }}" />
          <input matEndDate formControlName="endDate" placeholder="{{ 'invitations.placeholder.date-to' | translate }}"  />
        </mat-date-range-input>

        <mat-datepicker-toggle style="background-color: white" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker style="background-color: white" #picker></mat-date-range-picker>
      </mat-form-field>
    </div>

    <!-- FILTER BY STATUS FREQUENT ACCESS -->
    <ng-select *ngIf="showFilter('accessStatus')" class="pointer mx-2 input-select" placeholder="{{ 'building.settings.status' | translate }}" formControlName="accessStatus">
      <ng-option *ngFor="let option of accessStatus" [value]="option.value">
        <img [src]="option.icon" alt="icon" height="16">
        {{ option.display | translate }}
      </ng-option>
    </ng-select>

    <!-- FILTER BY STATUS RESERVATIONS -->
    <ng-select
      *ngIf="showFilter('reservationStatus')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'building.settings.status' | translate }}"
      formControlName="reservationStatus"
    >
      <ng-option *ngFor="let option of reservationStatus" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY TYPE FREQUENT ACCESS -->
    <ng-select
      *ngIf="showFilter('accessType')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'building.settings.type' | translate }}"
      formControlName="accessType"
    >
      <ng-option *ngFor="let option of accessType" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY TYPE FREQUENT ACCESS MACRO -->
    <ng-select
      *ngIf="showFilter('accessTypeMacro')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'building.settings.type' | translate }}"
      formControlName="accessType"
    >
      <ng-option *ngFor="let option of accessTypeMacro" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY DOCUMENT -->
    <input *ngIf="showFilter('docId')" class="form-control item-filter filter-input" formControlName="docId" placeholder="{{ 'modal.docId' | translate }}" />

    <!-- FILTER BY GROUPS -->
    <ng-select
      *ngIf="showFilter('groupId')"
      class="pointer input-select"
      placeholder="Grupos"
      bindValue="id"
      bindLabel="name"
      formControlName="groupId"
      (change)="selectedOption($event)"
    >
      <ng-template ng-header-tmp>
        <div class="text-center">
          <button class="btn btn-link container__button-text" (click)="onOpenModal(true)">+ {{ 'macroCommunity.group.createGroup' | translate }}</button>
        </div>
      </ng-template>
      <ng-option *ngFor="let group of groupUsers" [value]="group.id">{{ group.name }}</ng-option>
    </ng-select>


    <!--  FILTER BY STATUS FREQUENT ACCESS -->
    <ng-select
      *ngIf="showFilter('statusAccess')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'frequentAccess.placeholders.status' | translate }}"
      formControlName="statusAccess"
    >
      <ng-option *ngFor="let option of statusFrequentAccess" [value]="option.value"><img class="mr-1" [src]="option.icon">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY TYPE USER FREQUENT ACCESS -->
    <ng-select
      *ngIf="showFilter('typeAccess')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'frequentAccess.placeholders.type' | translate }}"
      formControlName="typeAccess"
    >
      <ng-option *ngFor="let option of typesFrequentAccess" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <!-- FILTER BY DAYS FREQUENT ACCESS -->
    <ng-select
      *ngIf="showFilter('daysAccess')"
      class="pointer mx-2 input-select"
      placeholder="{{ 'frequentAccess.placeholders.day' | translate }}"
      formControlName="daysAccess"
    >
      <ng-option *ngFor="let option of daysFrequentAccess" [value]="option.value">{{ option.display | translate }}</ng-option>
    </ng-select>

    <button (click)="clear()" class="filter-button-clear">{{ 'building.tab.invitations.clear' | translate }}</button>
    <button (click)="filter()" class="filter-button-search">{{ 'visit.filter' | translate }}</button>

  </form>
</div>
