import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'access-timeline',
  templateUrl: './access-timeline.component.html',
  styleUrls: ['./access-timeline.component.scss']
})
export class AccessTimelineComponent implements OnInit {
  @Input() accessEntries: any[] = [];

  doorAccessEntry = {
    ['GPCCN-E']: { key: 'frequentAccess.securityCenter.statusEntry.doorEntry', icon: 'entry' },
    ['GG-E']: { key: 'frequentAccess.securityCenter.statusEntry.doorEntry', icon: 'entry' },
    ['GG-S']: { key: 'frequentAccess.securityCenter.statusEntry.doorExit', icon: 'exit' },
    ['GPCCN-S']: { key: 'frequentAccess.securityCenter.statusEntry.doorExit', icon: 'exit' },
  };

  constructor() { }

  ngOnInit(): void {
  }

}
