<div class="body-card mt-3 ml-3">
  <div class="stepper-wrapper">
    <div class="stepper-item" [ngClass]="door.checked && door.status === 'completed' ? 'completed' : ''"
      *ngFor="let door of accessEntries; let i = index">

      <div class="step-name">
        <p>{{ door.doorName }}</p>
        <p>{{ door.date ? (door.date | date: 'HH:mm') : '--:--' }}</p>
      </div>

      <mat-icon [svgIcon]="door.checked ? 'check' : 'remove'" class="step-counter"
        [ngClass]="door.checked && door.status === 'completed' ? 'completed' : ''">
      </mat-icon>

      <div class="step-title">{{ (doorAccessEntry[door.doorName]?.key ?? '') | translate }}
        <mat-icon [svgIcon]="(doorAccessEntry[door.doorName]?.icon ?? '')"></mat-icon>
      </div>
    </div>
  </div>
</div>