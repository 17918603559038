import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef } from '@angular/core';
import { SecurityStateDTO, AccessEntryStatus, typeVehicle, AccessType } from '../../../../interface/frequentAccess.inteface';
import { isEmpty } from 'lodash';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { DAYS_GLOBALS } from 'src/app/interface/data.interface';

@Component({
  selector: 'app-security-admin-detail',
  templateUrl: './security-admin-detail.component.html',
  styleUrls: ['./security-admin-detail.component.scss']
})
export class SecurityAdminDetailComponent implements OnInit {
  modalRef: any;
  isExtensionOption: boolean = false;
  frequentAccessId: string = "";
  invitationId: string = "";
  hourAccess: String | Date;
  @Input() accessSelectedInfo: any;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @ViewChild('extendVisit', { static: true }) extendVisit: ElementRef;

  accessSelected: SecurityStateDTO;
  typeVehicleToShow: string = "";
  validVehicle: boolean = true;
  days: string[] = DAYS_GLOBALS;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
  ) {
    this.addSvgIcon('badge', 'assets/images/icons/icons-outline/Icon_action_badge.svg');
    this.addSvgIcon('call_end', 'assets/images/icons/icons-outline/Icon_comunication_call_end.svg');
    this.addSvgIcon('mail', 'assets/images/icons/icons-outline/Icon_comunication_mail.svg');
    this.addSvgIcon('garage_door', 'assets/images/icons/icons-outline/garage_door.svg');
    this.addSvgIcon('notifications_group', 'assets/images/icons/icons-outline/Icon_notifications_group.svg');
    this.addSvgIcon('date_range', 'assets/images/icons/icons-outline/Icon_action_date_range.svg');
    this.addSvgIcon('transportation', 'assets/images/icons/icons-outline/transportation.svg');
    this.addSvgIcon('garage', 'assets/images/icons/icons-outline/garage.svg');
    this.addSvgIcon('traffic_jam', 'assets/images/icons/icons-outline/traffic_jam.svg');
    this.addSvgIcon('car_crash', 'assets/images/icons/icons-outline/car_crash.svg');
    this.addSvgIcon('car', 'assets/images/icons/icons-outline/Icon_maps_directions_car.svg');
    this.addSvgIcon('badge', 'assets/images/icons/icons-outline/Icon_action_badge.svg');
    this.addSvgIcon('badge', 'assets/images/icons/icons-outline/Icon_action_badge.svg');
    this.addSvgIcon('edit', 'assets/images/icons/icons-outline/Icon_blue_edit_edit.svg');
    this.addSvgIcon('shared', 'assets/images/icons/icons-outline/Icon_share.svg');
    this.addSvgIcon('info', 'assets/images/icons/icons-outline/Icon_info-C93749.svg');
  }
  ngOnInit(): void {
    if (this.accessSelectedInfo.vehicle && !isEmpty(this.accessSelectedInfo.vehicle)) {
      this.getVehicle(this.accessSelectedInfo.vehicle.typeVehicle);
    } else {
      this.validVehicle = false;
      this.typeVehicleToShow = "N/A";
    }
    this.accessSelected = this.accessSelectedInfo;
    if (this.accessSelected.frequentAccessId['accessType'] === AccessType.unique && this.accessSelected.accessEntryStatus == AccessEntryStatus.active) {
      this.hourAccess = this.accessSelected.date;
      this.invitationId = this.accessSelected.invitationId;
      this.frequentAccessId = this.accessSelected.frequentAccessId['id'];
      this.isExtensionOption = !this.isExtensionOption;
    }
  }

  getVehicle(vehicle: string) {
    const transport: any = vehicle.toLowerCase();
    const keyIndex = Object.values(typeVehicle).indexOf(transport);
    if (keyIndex !== -1) {
      this.typeVehicleToShow = Object.keys(typeVehicle)[keyIndex];
    } else {
      this.validVehicle = false;
      this.typeVehicleToShow = vehicle;
    }
  }

  setStyleCardProgressBar(access: SecurityStateDTO) {
    if (access.accessEntry[2].status == AccessEntryStatus.timeExceeded) {
      return true;
    }
    return false;
  }

  setStyleProgressBar(status: string, checked: boolean) {
    if (!status && checked) return "";
    let accessStyle: string = "";
    switch (status) {
      case AccessEntryStatus.active:
        if (!checked) {
          accessStyle = "";
        }
        break;
      case AccessEntryStatus.displacement:
        accessStyle = AccessEntryStatus.displacement;
        break;
      case AccessEntryStatus.completed:
        if (checked) {
          accessStyle = AccessEntryStatus.completed;
        }
        break;
      case AccessEntryStatus.timeExceeded:
        accessStyle = AccessEntryStatus.timeExceeded;
        break;
      default:
        break;
    }
    return accessStyle;
  }

  openExtendVisit(): void {
    let ref = this.extendVisit;
    this.modalRef = this.modalService.open(ref, {
      windowClass: 'extend-visit-modal',
      backdropClass: 'blur-backdrop',
      backdrop: 'static',
      keyboard: false,
      centered: true,
      size: 'lg',
    })
  }

  close(): void {
    this.cancel.emit();
  }

  closeExtendVisit(e?: any): void {
    this.modalRef.close();
    if (e) this.close();
  }

  addSvgIcon(name: string, path: string) {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
